import * as actionTypes from "./actions";
// import config from './../config';

const initialState = {
  isOpen: [], //for active default menu
  isTrigger: [], //for active default menu, set blank for horizontal
  isSubOpen: [], //for active default sub menu
  isSubTrigger: [], //for active default sub menu, set blank for horizontal
  // ...config,
  isFullScreen: false, // static can't change
  platforms: [],
  channels: [],
  platformColors: [],
  licensors: [],
  roles: [],
  departments: [],
  demandPartners: [],
  digitalPartners: [],
  sources:[],
  passthroughChannels:[],
  defMonthRange: {},
};

const reducer = (state = initialState, action) => {
  let trigger = [];
  let open = [];
  switch (action.type) {
    case actionTypes.COLLAPSE_MENU:
      return {
        ...state,
        collapseMenu: !state.collapseMenu,
      };
    case actionTypes.COLLAPSE_TOGGLE:
      if (action.menu.type === "sub") {
        /*For multiple menu open*/
        
        // open = state.isSubOpen;
        // trigger = state.isSubTrigger;

        // const triggerIndex = trigger.indexOf(action.menu.id);
        // if (triggerIndex > -1) {
        //   open = open.filter((item) => item !== action.menu.id);
        //   trigger = trigger.filter((item) => item !== action.menu.id);
        // }

        // if (triggerIndex === -1) {
        //   open = [...open, action.menu.id];
        //   trigger = [...trigger, action.menu.id];
        // }

        
        open = state.isSubOpen;
        const triggerIndex = state.isSubTrigger.indexOf(action.menu.id);
        trigger = triggerIndex === -1 ? [action.menu.id] : [];
        open = triggerIndex === -1 ? [action.menu.id] : [];

        return {
          ...state,
          isSubOpen: open,
          isSubTrigger: trigger,
        };
      } else {
        open = state.isOpen;
        const triggerIndex = state.isTrigger.indexOf(action.menu.id);
        trigger = triggerIndex === -1 ? [action.menu.id] : [];
        open = triggerIndex === -1 ? [action.menu.id] : [];

        return {
          ...state,
          isOpen: open,
          isTrigger: trigger,
        };
      }
    case actionTypes.NAV_CONTENT_LEAVE:
      return {
        ...state,
        isOpen: open,
        isTrigger: trigger,
      };
    case actionTypes.NAV_COLLAPSE_LEAVE:
      // if (action.menu.type === "sub") {
      //   open = state.isOpen;
      //   trigger = state.isTrigger;

      //   const triggerIndex = trigger.indexOf(action.menu.id);
      //   if (triggerIndex > -1) {
      //     open = open.filter((item) => item !== action.menu.id);
      //     trigger = trigger.filter((item) => item !== action.menu.id);
      //   }
      //   return {
      //     ...state,
      //     isOpen: open,
      //     isTrigger: trigger,
      //   };
      // }
      return { ...state };
    case actionTypes.FULL_SCREEN:
      return {
        ...state,
        isFullScreen: !state.isFullScreen,
      };
    case actionTypes.FULL_SCREEN_EXIT:
      return {
        ...state,
        isFullScreen: false,
      };
    case actionTypes.CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.layout,
      };
    case actionTypes.FETCH_PLATFORM:
      return {
        ...state,
        platforms: action.payload,
      };
    case actionTypes.FETCH_CHANNEL:
      return {
        ...state,
        channels: action.payload,
      };
    case actionTypes.GET_DYNAMIC_COLOR_OBJ:
      return {
        ...state,
        graphDynamicColorObj: action.payload,
      };
    case actionTypes.FETCH_LICENSOR:
      return {
        ...state,
        licensors: action.payload,
      };
    case actionTypes.FETCH_PARTNERS:
      return {
        ...state,
        partners: action.payload,
      };
    case actionTypes.FETCH_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case actionTypes.FETCH_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };
    case actionTypes.FETCH_DEMANDPARTNERS:
      return {
        ...state,
        demandPartners: action.payload,
      };
    case actionTypes.FETCH_DIGITALPARTNERS:
      return {
        ...state,
        digitalPartners: action.payload,
      };
    case actionTypes.FETCH_LICENSORDETAILS:
      return {
        ...state,
        licensordetails: action.payload,
      };
    case actionTypes.DEFAULT_MONTH_RANGE:
      return {
        ...state,
        defMonthRange: action.payload,
      };
    case actionTypes.FETCH_USER_PERMISSION:
      return {
        ...state,
        userpermission: action.payload,
      };
    case actionTypes.FETCH_SOURCES:
      return {
        ...state,
        sources: action.payload,
      };
    case actionTypes.FETCH_PASSTHROUGHCHANNELS:
      return {
        ...state,
        passthroughChannels: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
