export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";

export const FETCH_PLATFORM = "FETCH_PLATFORM";
export const GET_DYNAMIC_COLOR_OBJ = "GET_DYNAMIC_COLOR_OBJ";
export const FETCH_CHANNEL = "FETCH_CHANNEL";
export const FETCH_LICENSOR = "FETCH_LICENSOR";
export const FETCH_PARTNERS = "FETCH_PARTNERS";
export const FETCH_ROLES = "FETCH_ROLES";
export const FETCH_DEPARTMENTS = "FETCH_DEPARTMENTS";
export const FETCH_DEMANDPARTNERS = "FETCH_DEMANDPARTNERS";
export const FETCH_DIGITALPARTNERS = "FETCH_DIGITALPARTNERS";
export const FETCH_LICENSORDETAILS = "FETCH_LICENSORDETAILS";
export const FETCH_USER_PERMISSION = "FETCH_USER_PERMISSION";
export const FETCH_SOURCES = "FETCH_SOURCES";
export const FETCH_PASSTHROUGHCHANNELS = "FETCH_PASSTHROUGHCHANNELS";

export const DEFAULT_MONTH_RANGE = "DEFAULT_MONTH_RANGE";
