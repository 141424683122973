import React, { Component, Suspense} from "react";
import { Switch, Route } from "react-router-dom";
import Loadable from "react-loadable";
import "../../node_modules/font-awesome/scss/font-awesome.scss";
import Loader from "../container/layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "../container/layout/ScrollToTop";
import cookie from "react-cookies";
import { ConVar } from "../js/ConfigVar";

const AdminLayout = Loadable({
  loader: () => import("../container/layout/AdminLayout"),
  loading: Loader,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signoutTime: 1000 * 60 * 60 * 8,
    };
  }
  componentDidMount() {
    if(cookie.load('login')){
      this.events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress',
      ];  
      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }  
      this.setTimeout();
    }    
  }

  clearTimeoutFunc = () => {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  };

  setTimeout = () => {
    this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
  };

  resetTimeout = () => {
    this.clearTimeoutFunc();
    let loadTime = new Date();
    cookie.remove("lastActivityTime");
    cookie.save('lastActivityTime', loadTime);
    this.setTimeout();
  };

  logout = () => {
    let currTime = new Date();
    let unloadTime = cookie.load('lastActivityTime') ? new Date(JSON.parse(cookie.load('lastActivityTime'))) :new Date() ;
    let refreshTime = currTime.getTime() - unloadTime.getTime();
    if(refreshTime >= this.state.signoutTime) {
      cookie.remove("login");
      cookie.remove("login", { path: "/" });
      cookie.remove(ConVar.authCookieName);
      cookie.remove(ConVar.authCookieName, { path: "/" });
      cookie.remove("token");
      cookie.remove("token", { path: "/" });
      window.history.replaceState(null, null, "/");
      window.location.href = "/login";
    }    
  };
  render() {
    // const menu = routes.map((route, index) => {
    //   return (route.component) ? (
    //       <Route
    //           key={index}
    //           path={route.path}
    //           exact={route.exact}
    //           name={route.name}
    //           render={props => (
    //               <route.component {...props} />
    //           )} />
    //   ) : (null);
    // });

    return (
      <Aux>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              {/* {menu} */}
              <Route path="/" component={AdminLayout} />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Aux>
    );
  }
}

export default App;
